import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import PageTitle from '../components/PageTitle';
import CardsList from '../components/CardsList';
import PageHeader from '../components/PageHeader';

const PressPage = ({data}) => {
    const {contentfulPage, allContentfulPost} = data;
    return (
        <>
            <Layout>
                <main>
                    <PageHeader>
                        <PageTitle>
                            <h1>{contentfulPage.title}</h1>
                        </PageTitle>
                    </PageHeader>
                    <Section hasMaxWidth isPressPage>
                        <CardsList items={allContentfulPost.nodes}/>
                    </Section>
                </main>
            </Layout>
        </>
    )
}

export default PressPage;

export function Head({data}) {
    const {contentfulPage} = data;
    return (
        <title>{contentfulPage.title} | Derschlag</title>
    )
}

export const query = graphql`
    query ($locale: String!) {
        contentfulPage(contentful_id: {eq: "3n0XdTPQagDM0DDRYSP55i"}, node_locale: {eq: $locale}) {
            id
            title
            slug
        }
        allContentfulPost(filter: {node_locale: {eq: $locale}}, sort: {fields: publishedAt, order: DESC}) {
            nodes {
                ... PostFields
            }
        }
    }
`;